<!--
 * @Description: 客户移交详情
 * @Author: ChenXueLin
 * @Date: 2021-11-05 15:13:23
 * @LastEditTime: 2022-07-26 17:59:42
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 客户移交信息   start -->
          <div class="baseInfo-box">
            <div class="edit-title">客户移交信息</div>
            <el-form
              ref="corpShiftForm"
              label-width="140px"
              label-position="right"
              :inline="true"
              :model="corpShiftForm"
              :rules="rules"
              :disabled="!!corpShiftForm.id"
              class="editForm"
            >
              <el-form-item label="单据编号" prop="documentNo">
                <el-input
                  v-model="corpShiftForm.documentNo"
                  disabled
                  placeholder="单据编号, 系统生成"
                ></el-input>
              </el-form-item>

              <el-form-item label="转出业务员" prop="rollOutSalesman">
                <el-input
                  v-if="!!corpShiftForm.id"
                  v-model="corpShiftForm.rollOutSalesmanName"
                  placeholder="转出业务员"
                  title="转出业务员"
                  disabled
                ></el-input>
                <all-user-search
                  v-else
                  v-model="corpShiftForm.rollOutSalesman"
                  clear
                  placeholder="转出业务员"
                  title="转出业务员"
                  :propsConfig="{
                    id: 'employeeId',
                    label: 'userName'
                  }"
                  :queryListAPI="findAllUserRelation"
                  :initValue="rollOutSalesmanInfo"
                  :showSlot="true"
                >
                  <!--  @change="handleChangeRollOut" -->
                </all-user-search>
              </el-form-item>

              <el-form-item label="转入业务员" prop="rollInSalesman">
                <el-input
                  v-if="!!corpShiftForm.id"
                  v-model="corpShiftForm.rollInSalesmanName"
                  placeholder="转入业务员"
                  title="转入业务员"
                  disabled
                ></el-input>
                <all-user-search
                  v-else
                  v-model="corpShiftForm.rollInSalesman"
                  clear
                  placeholder="转入业务员"
                  title="转入业务员"
                  :propsConfig="{
                    id: 'employeeId',
                    label: 'userName'
                  }"
                  :showSlot="true"
                >
                </all-user-search>
              </el-form-item>
              <el-form-item label="申请日期" prop="applyTime">
                <el-date-picker
                  v-model="corpShiftForm.applyTime"
                  value-format="timestamp"
                  type="date"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="备注" prop="remark">
                <el-tooltip
                  :disabled="
                    corpShiftForm.remark.length < 25 || !corpShiftForm.id
                  "
                  class="item"
                  effect="dark"
                  :content="corpShiftForm.remark"
                  placement="top"
                >
                  <el-input
                    v-model="corpShiftForm.remark"
                    maxlength="100"
                  ></el-input>
                </el-tooltip>
              </el-form-item>
            </el-form>
          </div>
          <div class="tabs-wrapper">
            <div>移交明细</div>
            <section class="operate-button">
              <div class="operate-action">
                <i
                  class="e6-icon-add_line"
                  title="新增"
                  v-show="!corpShiftForm.id"
                  @click="openSelectCustomerDialog"
                ></i>
                <i
                  class="e6-icon-clear_line"
                  v-show="!corpShiftForm.id"
                  title="删除"
                  @click="deleteDetails"
                ></i>
              </div>
            </section>
          </div>
          <template>
            <el-table
              border
              height="300px"
              :data="tableData"
              highlight-current-row
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="40"></el-table-column>
              <el-table-column
                show-overflow-tooltip
                v-for="(column, index) in columnData"
                :key="index"
                :prop="column.fieldName"
                :label="column.fieldLabel"
                :min-width="column.width"
                :fixed="column.fixed"
                :align="column.align"
                header-align="center"
              >
              </el-table-column>
            </el-table>
          </template>
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button type="primary" @click="handleSumit">确定</el-button>
        </div>
      </section>
      <selectCustomerDialog
        ref="selectCustomerDialog"
        v-if="selectCustomerDialogVisible"
        @change="selectCustome"
      />
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import goBack from "@/mixins/goBack";
import { corpShiftDetail, createCorpShift, findAllUserRelation } from "@/api";
import { printError } from "@/utils/util";
import selectCustomerDialog from "./selectCustomerDialog";
// import { mapState } from "vuex";
import allUserSearch from "@/components/allUserSearch";
export default {
  name: "customerHandOverDetail",
  components: { selectCustomerDialog, allUserSearch },
  mixins: [listPage, listPageReszie, base, goBack],
  data() {
    return {
      selectCustomerDialogVisible: false,
      findAllUserRelation,
      corpShiftForm: {
        documentNo: "", // 单据编号系统生成
        rollOutSalesman: "", // 转出业务员
        rollInSalesman: "", // 转入业务员
        applyTime: new Date().getTime(), // 申请日期
        remark: "", // 备注
        detail: []
      },

      columnData: [
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "arrearage",
          display: true,
          fieldLabel: "欠款",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "contactName",
          display: true,
          fieldLabel: "客户联系人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "phone",
          display: true,
          fieldLabel: "联系电话",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      selColumnId: []
    };
  },
  computed: {
    rules() {
      return {
        rollOutSalesman: [
          {
            required: true,
            message: "请选择转出业务员",
            trigger: ["blur", "change"]
          }
        ],
        rollInSalesman: [
          {
            required: true,
            message: "请选择转入业务员",
            trigger: ["blur", "change"]
          }
        ],
        applyTime: [
          {
            required: true,
            message: "请选择申请时间",
            trigger: ["blur", "change"]
          }
        ]
      };
    },
    rollOutSalesmanInfo() {
      let userInfo = this.$store.state.userInfo;
      let rollOutSalesmanInfo = userInfo.employeeId
        ? [
            {
              employeeId: userInfo.employeeId,
              userId: userInfo.userName,
              employeeName: userInfo.userName,
              userName: userInfo.userName
            }
          ]
        : [];
      return rollOutSalesmanInfo;
    }
  },
  watch: {},
  created() {
    this.initForm();
  },
  activated() {
    this.handleRefreshUpdatePage();
  },
  methods: {
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      let id =
        hasParamsRoute.params.id || hasParamsRoute.params.approvalBusinessId;
      this.corpShiftForm.id = id;
      if (this.corpShiftForm.id) {
        this.queryDetail();
      } else {
        if (this.$refs.corpShiftForm) this.$refs.corpShiftForm.resetFields();
        this.tableData = [];
        this.corpShiftForm.rollOutSalesman = this.$store.state.userInfo.employeeId;
      }
    },

    //获取客户移交详情
    async queryDetail() {
      try {
        this.loading = true;
        let id = this.corpShiftForm.id;
        let res = await corpShiftDetail({ id });
        let data = this.getFreezeResponse(res, {
          path: "data"
        });
        this.corpShiftForm = JSON.parse(JSON.stringify(data));
        this.corpShiftForm.id = id;
        this.tableData = this.corpShiftForm.detail;
        if (this.corpShiftForm.applyTime) {
          let applyTime = this.corpShiftForm.applyTime;
          applyTime = applyTime.replace(/-/g, "/");
          applyTime = new Date(applyTime).getTime();
          this.corpShiftForm.applyTime = applyTime;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleLoadRollOutSalesman: _.debounce(async function(val) {
      if (val) {
        this.loadEmployeeData(val, 1);
      }
    }, 300),

    handleSumit() {
      if (this.corpShiftForm.id) {
        this.handleGoBack();
        return;
      }
      let { rollInSalesman, rollOutSalesman } = this.corpShiftForm;
      if (rollInSalesman === rollOutSalesman) {
        this.$message.warning("转出转入业务员不能为同一个");
        return;
      }
      this.$refs.corpShiftForm.validate(valid => {
        if (valid) {
          this.createCorpShift();
        }
      });
    },

    // 新增客户移交
    async createCorpShift() {
      try {
        this.loading = true;
        this.corpShiftForm.detail = this.tableData.map(item => {
          return {
            corpId: item.corpId,
            contactId: item.contactId,
            phone: item.phone
          };
        });
        let res = await createCorpShift(this.corpShiftForm);
        if (res.code == "OK") {
          this.$message.success(
            res.msg && res.msg.indexOf("审批中") !== -1
              ? "已进入飞书审批流程"
              : res.msg
          );
          this.handleGoBack(true);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 打开弹窗
    openSelectCustomerDialog() {
      if (!this.corpShiftForm.rollOutSalesman) {
        this.$message.warning("请先选中转出业务员");
        return;
      }
      this.selectCustomerDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.selectCustomerDialog.init(
          this.corpShiftForm.rollOutSalesman
        );
      });
    },
    // 删除明细
    deleteDetails() {
      this.selColumnId.map(item => {
        let index = this.tableData.findIndex(one => item.corpId === one.corpId);
        this.tableData.splice(index, 1);
      });
      this.selColumnId = [];
      this.$message.success("删除成功");
    },
    // 表格多选
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return item;
      });
    },
    // 选择转出客户明细
    selectCustome(data) {
      if (data && data.length) {
        let changeCount = 0;
        let insertCount = 0;
        data.map(item => {
          let target = this.tableData.find(one => {
            return item.corpId === one.corpId;
          });
          if (target) {
            target.contactId = item.contactId;
            target.phone = item.phone;
            target.contactName = item.contactName;
            changeCount++;
          } else {
            this.tableData.push(item);
            insertCount++;
          }
        });
        this.$message.success(
          `成功插入${insertCount}条明细，修改${changeCount}条明细`
        );
      }
    }
  }
};
</script>
<style lang="scss" scoped>
// .tabs-wrapper {
//   height: 48px;
//   padding-left: 15px;
//   line-height: 48px;
//   font-size: 14px;
//   box-sizing: border-box;
//   color: #303133;
//   border-width: 1px 0;
//   border-style: solid;
//   border-color: #f5f7fa;
//   background-color: #f9fafc;
//   box-sizing: border-box;
// }
</style>
